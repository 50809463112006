<template>
  <div :class="`wrapper wrapper--border wrapper--${bu} m-3 p-2`">
    <div class="text-center p-2">
      <h2 class="text-center">Resultaat per maand</h2>
      <span @click="previousMonth"><i class="fas fa-arrow-left"></i></span>
      <span class="text-center m-2">Maand: {{ month_number }}</span>
      <span @click="nextMonth"><i class="fas fa-arrow-right"></i></span>
    </div>
    <Loading v-if="loading" />
    <b-tabs
      v-if="!loading"
      content-class="mt-3"
      justified
      pills
      active-nav-item-class="active-nav-class"
      nav-class="nav-control-class"
    >
      <b-tab title="Totaal" active
        ><table :class="`w-100 table--default table__border--${bu}`">
          <thead :class="`table__head--${bu}`">
            <tr>
              <th class="table__head--text">Inkoper</th>
              <th class="table__head--text table__data--right">Aantal</th>
              <th class="table__head--text table__data--right">Inkopen</th>
              <th class="table__head--text table__data--right">Kosten</th>
              <th class="table__head--text table__data--right">Verkopen</th>
              <th class="table__head--text table__data--right">Winst</th>
              <th class="table__head--text table__data--right">Afschrijving</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, key) in total_results"
              :key="key"
              :class="`table__row--color`"
            >
              <td class="table__cell--default">{{ item.naam }}</td>
              <td class="table__cell--default table__data--right">{{ item.aantal }}</td>
              <td class="table__cell--default table__data--right">€ {{ item.inkopen }}</td>
              <td class="table__cell--default table__data--right">€ {{ item.kosten }}</td>
              <td class="table__cell--default table__data--right">€ {{ item.verkopen }}</td>
              <td class="table__cell--default table__data--right">€ {{ item.winst }}</td>
              <td class="table__cell--default table__data--right">€ {{ item.afschrijving }}</td>
            </tr>
          </tbody>
          <tfoot class="table__foot">
            <tr>
              <th class="table__foot--padding">Totaal:</th>
              <th class="table__foot--padding table__data--right">{{ total_results_sum.aantal }}</th>
              <th class="table__foot--padding table__data--right">
                € {{ total_results_sum.inkopen }}
              </th>
              <th class="table__foot--padding table__data--right">
                € {{ total_results_sum.kosten }}
              </th>
              <th class="table__foot--padding table__data--right">
                € {{ total_results_sum.verkopen }}
              </th>
              <th class="table__foot--padding table__data--right">
                € {{ total_results_sum.winst }}
              </th>
              <th class="table__foot--padding table__data--right">
                € {{ total_results_sum.afschrijving }}
              </th>
            </tr>
          </tfoot>
        </table></b-tab
      >
      <b-tab
        v-for="(salesman, key) in results_per_salesman"
        :key="key"
        :title="`${key}`"
      >
        <table :class="`w-100 table--default table__border--${bu}`">
          <thead :class="`table__head--${bu}`">
            <tr>
              <th class="table__head--text">Foto</th>
              <th class="table__head--text">Item</th>
              <th class="table__head--text table__data--right">Inkopen</th>
              <th class="table__head--text table__data--right">Kosten</th>
              <th class="table__head--text table__data--right">Commissie</th>
              <th class="table__head--text table__data--right">Verkopen</th>
              <th class="table__head--text table__data--right">Winst</th>
              <th class="table__head--text table__data--right">Afschrijving</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, key) in salesman"
              :key="key"
              :class="`table__row--color`"
            >
              <td class="table__cell--default">
                <img
                  :src="`https://apps.kleyn.com:1919/prod/public/data/mainimage/${item.nummer}/thumb`"
                />
              </td>
              <td class="table__cell--default">
                <ImageHover :itemnummer="item.nummer" :bu="bu" />
                {{ item.soortnaam }}<br />
                {{ item.merknaam }}<br />
                {{ item.typenaam }}<br />
                {{ item.regjaar }}
              </td>
              <td class="table__cell--default table__data--right">€ {{ item.inkoopbedrag }}</td>
              <td class="table__cell--default table__data--right">€ {{ item.kostenbedrag }}</td>
              <td class="table__cell--default table__data--right">€ {{ item.commisiebedrag }}</td>
              <td class="table__cell--default table__data--right">€ {{ item.verkoopbedrag }}</td>
              <td class="table__cell--default table__data--right">€ {{ item.winst }}</td>
              <td class="table__cell--default table__data--right">€ {{ item.afschrijving }}</td>
            </tr>
          </tbody>
        </table>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import request from "@/functions/request.js";
import ImageHover from "@/components/ImageHover.vue";
import moment from "moment";
import {
  getYearNumber,
  getMonthNumber,
  subtractTime,
  addTime,
} from "@/functions/date.js";
import Loading from "@/components/Loading";

export default {
  props: ["bu"],
  components: { Loading, ImageHover },
  data: () => ({
    full_date: null,
    year_number: null,
    month_number: null,

    results_per_salesman: null,
    total_results: null,
    total_results_sum: null,
    loading: null,
  }),
  created() {
    this.full_date = moment();
    this.getData(this.bu);
  },
  watch: {
    bu(newbu) {
      this.getData(newbu);
    },
  },
  methods: {
    getData(bu) {
      this.loading = true;
      this.year_number = getYearNumber(this.full_date);
      this.month_number = getMonthNumber(this.full_date);
      const result_url = `results-per-month/${this.year_number}/${this.month_number}/${bu}`;
      request(result_url, "GET").then(
        ({ results_per_salesman, results_total }) => {
          const { total_results, total_results_sum } = results_total;
          this.results_per_salesman = results_per_salesman;
          this.total_results = total_results;
          this.total_results_sum = total_results_sum;
          this.loading = false;
        }
      );
    },

    previousMonth() {
      this.full_date = subtractTime(1, this.full_date, "months");
      this.getData(this.bu);
    },

    nextMonth() {
      this.full_date = addTime(1, this.full_date, "months");
      this.getData(this.bu);
    },
  },
};
</script>
